import React, { useState, useEffect, useMemo, useRef } from 'react';
import DataGrid from '../../../../components/DataSheetGrid';
import { textColumn, keyColumn, Column, dateColumn } from 'react-datasheet-grid';
import { getDate, parseISO, getDaysInMonth, format } from 'date-fns';
import { Avatar, Button, Popover, Select, Tooltip, Overlay } from '@mantine/core';
import useStyles from './DailyTargetsStyles';
import { commonFetch, fetchWBSActivities, upsertTargets, reCalculateTargets, unitActivityUpdate } from '../../../../api/transactionServer';
import { useStore } from '../../../../models/ProvideModel';
import { CommonApiEntities, DailyPlanningModes } from '../../../../models/enums';
import { Instance } from 'mobx-state-tree';
import { StoreModel } from '../../../../models/DataStore';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { IconRefresh, IconDeviceFloppy, IconTargetArrow, IconCircleFilled } from '@tabler/icons-react';
import LoadingSpinner from '../../../../components/loadingSkelaton/LoadingSpinner';
import NoDataForTargets from '../noData/NoDataForTargets';
import { Divider } from '@material-ui/core';
import { DatePickerInput } from '@mantine/dates';
import { formatPercentage } from '../../../../utils/utils';

function getMonthDateRange(year, month) {
  const startDate = new Date(year, month - 1, 1).toISOString().slice(0, 10) + ' 18:30:00.000';
  const endDate = new Date(year, month).toISOString().slice(0, 10) + ' 18:29:59.999';
  return { startDate, endDate };
}

const transformWBSData = async ({ store, year, month }: { store: Instance<typeof StoreModel>; year: number; month: number; }) => {
  const dates = getMonthDateRange(year, month);
  let data: any[] = [];
  const { data: unitActivityData } = await commonFetch({
    projectId: store.projectInfo.currentProject?.id,
    entity: CommonApiEntities.UNIT_ACTIVITIES,
    filters: { isDPR: true, planStart: dates.endDate, planEnd: dates.startDate }
  }).catch((err) => { console.error(err); throw new Error() });

  if (unitActivityData?.length) {
    const { data: wbsDataForTheFrequency } = await fetchWBSActivities({
      projectId: store.projectInfo.currentProject?.id,
      unitActivityIds: unitActivityData.map((ua) => ua.id),
      frequency: 'DAY,MONTH',
      targetPeriod: `${year}-${month.toLocaleString("en-GB", { minimumIntegerDigits: 2 })}`
    }).catch((err) => { console.error(err); throw new Error() });

    const activityMap = {};

    unitActivityData.forEach((unitAct) => {
      const unitActivityId = unitAct.id;
      const existingWBS = wbsDataForTheFrequency?.filter((wbs) => wbs.unitActivityId === unitActivityId) || [];

      activityMap[unitActivityId] = {
        unitTitle: unitAct.unitTitle,
        unitActivity: unitAct.id,
        activity: unitAct.title,
        plannedStart: moment(unitAct.planStart).format('DD-MM-YYYY'),
        plannedEnd: moment(unitAct.planEnd).format('DD-MM-YYYY'),
        plannedTotalQty: unitAct.plannedTotalQuantity,
        unitOfMeasure: unitAct.unitOfMeasure,
        actualStart: moment(unitAct.actualStart).format('DD-MM-YYYY'),
        blockName: unitAct.blockName,
        floorLabel: unitAct.floorLabel,
        unitTypeId: unitAct.unitTypeId,
        changedBy: 'SYSTEM'
      };

      existingWBS.forEach((wbs) => { //If an unit activity has more than one targets for the month
        if (wbs.frequency === 'DAY') {
          const day = getDate(parseISO(wbs.targetPeriod));
          const targetMonth = wbs.targetPeriod.split('-')[1]
          if (month === Number.parseInt(targetMonth)) {//achievedQuantity
            activityMap[unitActivityId][`day${day}`] = wbs.quantity  // (wbs.quantity && wbs.quantity >=0) ? Number.parseFloat(wbs.quantity).toFixed(2) : null;
            activityMap[unitActivityId]['quantities'] = {
              ...activityMap[unitActivityId]['quantities'],
              [`day${day}`]: {
                'target': wbs.quantity,
                'achieved': wbs.achievedQuantity
              }
            }
            // activityMap[unitActivityId]['totalAchievedForMonthSoFar'] += wbs.achievedQuantity
          }
        }
        if (wbs.frequency === 'MONTH') {
          const targetMonth = wbs.targetPeriod.split('-')[1]
          if (month === Number.parseInt(targetMonth)) {
            activityMap[unitActivityId][`monthsTarget`] = wbs.quantity  // (wbs.quantity && wbs.quantity >=0) ? Number.parseFloat(wbs.quantity).toFixed(2) : null;
            activityMap[unitActivityId]['totalAchievedForMonthSoFar'] = wbs.achievedQuantity
          }
        }
      });
    });

    const sortedData = Object.values(activityMap).sort((a: any, b: any) => {
      // Sort by unitTitle
      // const unitTitleComparison = a.unitTitle.localeCompare(b.unitTitle);
      // if (unitTitleComparison !== 0) return unitTitleComparison;

      // Sort by activity
      // const activityComparison = a.activity.localeCompare(b.activity);
      // if (activityComparison !== 0) return activityComparison;

      // Sort by plannedStart (handle missing or invalid dates)
      const dateA = a.plannedStart ? moment(a.plannedStart, "DD-MM-YYYY").valueOf() : 0;
      const dateB = b.plannedStart ? moment(b.plannedStart, "DD-MM-YYYY").valueOf() : 0;
      return dateA - dateB;
    });
    // data = Object.values(activityMap);
    data = sortedData;
  }

  return data;
};

const DailyTargets = () => {
  const classes = useStyles();
  const store = useStore();
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const [data, setData] = useState<any[]>([]);
  const [changes, setChanges] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const dailyPlanningmode = store.planning.features['DPR']['metadata']['dailyPlanningMode'];
  const [calculationLoading, setCalculationLoading] = useState(false);
  const dayOffs = store.planning.features?.dayOffs
  const currentMonth = moment().month() + 1;

  const baseColumns: Column<any>[] = [
    {
      ...keyColumn('plannedTotalQty', textColumn),
      title: 'Total QTY',
      minWidth: 100,
      disabled: true,
      cellClassName: `${classes.cellChanged}`,
    },
    {
      ...keyColumn('plannedStart', {
        component: ({ rowData, rowIndex, setRowData, focus }) => {
          return (
            <DatePickerInput
              value={rowData ? moment(rowData, "DD-MM-YYYY").toDate() : null}
              onChange={(val) => {
                const formattedDate = val ? moment(val).format("DD-MM-YYYY") : "";
                setRowData(formattedDate);
              }}
              placeholder="Select date"
              size="xs"
              // clearable
              dropdownType="modal" // Prevents UI glitches when inside a grid
              onClick={(e) => e.stopPropagation()} // Prevents accidental cell deselection
              autoFocus={focus} // Ensures smooth keyboard navigation
              styles={{ input: { cursor: "pointer", textAlign: "center" } }}
              inputMode='text'
              disabled={year < currentYear || month < currentMonth || data[rowIndex]['actualStart']}
              sx={{
                ["& .mantine-DatePickerInput-label"]: { fontSize: '0.8525em', color: '#585858', fontWeight: 600 },
                ["& .mantine-DatePickerInput-input"]: { marginTop: '0.5em', fontSize: '0.9em', marginBottom: '0.5em', borderRadius: 0, border: 'none', paddingRight: '1.8575rem', '&[data-disabled]': { backgroundColor: '#FAFAFA', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8575rem' } },
                ["& .mantine-DatePickerInput-icon"]: { justifyContent: 'center' },
              }}
              valueFormat="DD/MMM/YYYY"
            />
          );
        },
        pasteValue: (value: any) => {
          // Ensure `value` is an object
          if (typeof value === "object" && value !== null) {

            //  Extract the correct string date
            let extractedValue = "";
            if (typeof value.value === "string") {
              extractedValue = value.value; // Direct string value
            } else if (typeof value.value === "object" && value.value !== null) {
              extractedValue = value.value?.rowData || ""; // Try nested value
            } else {
              extractedValue = value.rowData || "";
            }

            // Ensure it's a valid string
            if (typeof extractedValue !== "string") {
              return "";
            }

            // Trim and parse date
            const cleanedValue = extractedValue.trim();

            const parsedDate = moment(cleanedValue, ["DD-MM-YYYY", "YYYY-MM-DD"], true);
            if (!parsedDate.isValid()) {
              return "";
            }

            const formattedDate = parsedDate.format("DD-MM-YYYY");
            return formattedDate;
          }

          // If it's already a string, process it directly
          if (typeof value === "string") {
            const parsedDate = moment(value.trim(), ["DD-MM-YYYY", "YYYY-MM-DD"], true);
            return parsedDate.isValid() ? parsedDate.format("DD-MM-YYYY") : "";
          }

          return "";
        }
      }),
      title: 'Planned start',
      minWidth: 120,
      cellClassName: `${classes.cellChanged}`,
      disableKeys: false,
      deleteValue: () => "",
      copyValue: ({ rowData }) => rowData["plannedStart"],
      disabled: ({ rowData }) => year < currentYear || month < currentMonth || rowData["actualStart"]
    },
    {
      ...keyColumn('plannedEnd', {
        component: ({ rowData, setRowData, focus }) => {
          return (
            <DatePickerInput
              value={rowData ? moment(rowData, "DD-MM-YYYY").toDate() : null}
              onChange={(val) => {
                const formattedDate = val ? moment(val).format("DD-MM-YYYY") : "";
                setRowData(formattedDate);
              }}
              placeholder="Select date"
              size="xs"
              // clearable
              dropdownType="modal" // Prevents UI glitches when inside a grid
              onClick={(e) => e.stopPropagation()} // Prevents accidental cell deselection
              autoFocus={focus} // Ensures smooth keyboard navigation
              styles={{ input: { cursor: "pointer", textAlign: "center" } }}
              inputMode='text'
              disabled={year < currentYear || month < currentMonth}
              sx={{
                ["& .mantine-DatePickerInput-label"]: { fontSize: '0.8525em', color: '#585858', fontWeight: 600 },
                ["& .mantine-DatePickerInput-input"]: { marginTop: '0.5em', fontSize: '0.9em', marginBottom: '0.5em', borderRadius: 0, border: 'none', paddingRight: '1.8575rem', '&[data-disabled]': { backgroundColor: '#FAFAFA', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8575rem' } },
                ["& .mantine-DatePickerInput-icon"]: { justifyContent: 'center' },
              }}
              valueFormat="DD/MMM/YYYY"
            />
          );
        },
        pasteValue: (value: any) => {
          // Ensure `value` is an object
          if (typeof value === "object" && value !== null) {

            //  Extract the correct string date
            let extractedValue = "";
            if (typeof value.value === "string") {
              extractedValue = value.value; // Direct string value
            } else if (typeof value.value === "object" && value.value !== null) {
              extractedValue = value.value?.rowData || ""; // Try nested value
            } else {
              extractedValue = value.rowData || "";
            }

            // Ensure it's a valid string
            if (typeof extractedValue !== "string") {
              return "";
            }

            // Trim and parse date
            const cleanedValue = extractedValue.trim();

            const parsedDate = moment(cleanedValue, ["DD-MM-YYYY", "YYYY-MM-DD"], true);
            if (!parsedDate.isValid()) {
              return "";
            }

            const formattedDate = parsedDate.format("DD-MM-YYYY");
            return formattedDate;
          }

          // If it's already a string, process it directly
          if (typeof value === "string") {
            const parsedDate = moment(value.trim(), ["DD-MM-YYYY", "YYYY-MM-DD"], true);
            return parsedDate.isValid() ? parsedDate.format("DD-MM-YYYY") : "";
          }

          return "";
        }
      }),
      title: 'Planned end',
      minWidth: 120,
      cellClassName: `${classes.cellChanged}`,
      disableKeys: false,
      deleteValue: () => "",
      copyValue: ({ rowData }) => rowData["plannedEnd"],
      disabled: year < currentYear || month < currentMonth
    },
  ];

  const columns = useMemo(() => {
    const daysInMonth = getDaysInMonth(new Date(year, month - 1));
    const dayColumns: Column<any>[] = Array.from({ length: daysInMonth }, (_, index) => {
      const date = new Date(year, month - 1, index + 1);
      const dayOfWeek = date.toLocaleString('en-US', { weekday: 'long' }).toUpperCase(); // Convert day to uppercase
      const yearString = date.getFullYear().toString();
      const dateString = format(date, 'yyyy-MM-dd'); // Convert date to YYYY-MM-DD format
      const isWeekend = dayOffs?.weekOffDays.includes(dayOfWeek) || (dayOffs?.holidays[yearString] && dayOffs.holidays[yearString].includes(dateString));
      const today = moment().startOf('day').toDate();
      const dateStringOther = format(date, 'dd-MM-yyyy');
      // const isWeekend = date.getDay() === 0
      return {
        ...keyColumn(`day${index + 1}`, (date < today) ? {
          component: ({ rowData, setRowData, rowIndex, focus }) => {
            const targetValue = rowData >= 0 && data[rowIndex]['quantities'][`day${index + 1}`]?.target;
            const achievedValue = rowData >= 0 && data[rowIndex]['quantities'][`day${index + 1}`]?.achieved;
            // const totalPercentAchieved = ((Number(achievedValue) / Number(targetValue)) * 100).toFixed(2);
            const totalPercentAchieved = formatPercentage(achievedValue, targetValue)
            // if(isWeekend) {
            //   console.log(`Date: ${dateStringOther} | Achieved: ${data[rowIndex]['quantities'][`day${index + 1}`]?.achieved} | Target: ${targetValue}`);
            // }
            return (
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '0em 0.5em', cursor: 'pointer' }}>
                {(rowData >= 0) && (
                  <>
                    <div style={{ marginTop: '-1.4em' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar color="teal" size={'xs'} style={{ fontSize: '0.8em' }}>A</Avatar>
                          <span style={{ marginLeft: '0.25em', fontSize: '0.85em', opacity: 0.5 }}>{achievedValue}</span>
                        </div>
                        <Divider style={{ width: '20%' }} />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar color="red" size={'xs'} style={{ fontSize: '0.8em' }}>T</Avatar>
                          <span style={{ marginLeft: '0.25em', fontSize: '1.1em' }}>{targetValue}</span>
                        </div>
                      </div>
                  </>
                )}
                {achievedValue > 0 && targetValue > 0 &&
                <Overlay opacity={0} zIndex={25}>
                  <Tooltip
                        label={
                          <div style={{ display: 'flex', alignSelf: 'end', bottom: 0 }}>
                            {totalPercentAchieved}% achieved
                          </div>
                        }
                        radius={'xs'}
                        color={Number.parseFloat(totalPercentAchieved) >= 100 ? 'teal' : 'red'}
                    >
                  <div style={{
                    position: 'absolute', // ✅ Ensures it's overlaid correctly
                    bottom: '4px', // ✅ Fixes position to the bottom
                    // left: '0%', // ✅ Centers it horizontally
                    // transform: 'translateX(-50%)', // ✅ Ensures proper centering
                    fontSize: '0.75em',
                    // backgroundColor: 'rgba(255, 255, 255, 0.9)', 
                    padding: '2px 5px',
                    borderRadius: '4px',
                    color: Number.parseFloat(totalPercentAchieved) >= 100 ? 'teal' : 'red',
                    alignItems: 'center',
                    display: 'flex'
                  }}>
                    <IconCircleFilled 
                    color={Number.parseFloat(totalPercentAchieved) >= 100 ? 'teal' : 'red'}
                    style={{ marginRight: '0.5em' }}
                    width={'1em'} 
                    height={'1em'}/> {totalPercentAchieved}%
                  </div>
                  </Tooltip>
                </Overlay>}
              </div>
            )
          },
          deleteValue: () => "",
          // copyValue: ({ rowData }) => rowData || "",
          copyValue: ({ rowData }) => (rowData ?? "").toString(),
          disableKeys: false, // Allows keyboard interactions (copy-paste, enter, etc.)
          keepFocus: true,
          // pasteValue: (value) => {
          //   const parsedValue = value.value.trim();
          //   return isNaN(parsedValue) ? "" : parsedValue;
          // },
        } : textColumn),
        // title: `${index + 1}`,
        title: `${format(date, 'dd')} ${format(date, 'EEE')}`,
        // cellClassName: ({ rowData, rowIndex }) => changes[rowIndex] && changes[rowIndex][`day${index + 1}`] ? `${classes.cellChanged}` : '',
        cellClassName: ({ rowData, rowIndex }) => {
          const plannedStart = moment(data[rowIndex]?.plannedStart, "DD-MM-YYYY");
          const plannedEnd = moment(data[rowIndex]?.plannedEnd, "DD-MM-YYYY");
          const cellDate = moment(dateStringOther, "DD-MM-YYYY");
          // return (dateStringOther >= rowData["plannedStart"] && dateStringOther <= rowData["plannedEnd"]) ? `${classes.plannedCell}` : `${classes.cellChanged}`
          if (cellDate.isBetween(plannedStart, plannedEnd, undefined, "[]") && cellDate >= moment(today, "DD-MM-YYYY") && !isWeekend && dailyPlanningmode !== DailyPlanningModes.SYSTEM) {
            return classes.plannedCell;
          }
          if (changes[rowIndex] && changes[rowIndex][`day${index + 1}`] && changes[rowIndex]["changedBy"] === 'USER' && cellDate >= moment(today, "DD-MM-YYYY") && data[rowIndex][`day${index + 1}`] !== changes[rowIndex][`day${index + 1}`]) {
            return classes.updatedCell;
          }
          return classes.cellChanged; // Default case
        },
        disabled: isWeekend || dailyPlanningmode === DailyPlanningModes.SYSTEM || date < today
      }
    });
    return [
      ...baseColumns,
      ...dayColumns];
  }, [year, month, changes]);


  const loadData = async () => {
    setLoading(true);
    setIsSaveButtonDisabled(true);
    const transformedData = await transformWBSData({ store, year, month });
    setData(transformedData);
    setChanges(transformedData);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [year, month]);

  const handleGridChange = (updatedData) => {
    setChanges((prevChanges) => {
      const newChanges = { ...prevChanges };
      updatedData.forEach((row, rowIndex) => {
        Object.keys(row).forEach((key) => {
          if (row[key] !== prevChanges[rowIndex]?.[key]) {
            const newValue = row[key];

            if (!newChanges[rowIndex]) newChanges[rowIndex] = {};

            if (key === "plannedEnd" || key === "plannedStart") {
              const formattedDate = newValue ? moment(newValue, "DD-MM-YYYY").format("DD-MM-YYYY") : null;
              if (formattedDate !== prevChanges[rowIndex]?.[key]) {
                newChanges[rowIndex][key] = formattedDate;
                newChanges[rowIndex]['changedBy'] = 'USER';
              }
            } else {
              if (newValue !== prevChanges[rowIndex]?.[key]) {
                newChanges[rowIndex][key] = (newValue >= 0 && !isNaN(newValue)) ? newValue : null;
                newChanges[rowIndex]['changedBy'] = 'USER';
              }
            }
          }
        });
      });
      setIsSaveButtonDisabled(false);  // Enable Save button on any change
      return newChanges;
    });
  };

  const handleSaveChanges = async () => {
    const scheduleList: any = [];
    const plannedUpdates: Record<string, any> = {}; //  Store plannedStart & plannedEnd in one object per unitActivityId

    Object.entries(changes).forEach(([rowIndex, rowData]) => {
      const originalData = data[rowIndex];
      const unitActivityId = originalData.unitActivity;

      Object.entries(rowData as Record<string, any>).forEach(([key, value]) => {
        if (key.startsWith("day") && value && value >= 0 && rowData && rowData["changedBy"] === "USER") {
          const day = key.replace("day", "").padStart(2, "0");
          const targetPeriod = `${year}-${month.toString().padStart(2, "0")}-${day}`;

          scheduleList.push({
            unitActivityId: unitActivityId,
            frequency: "DAY",
            targetPeriod: targetPeriod,
            quantity: Number.parseFloat(value).toFixed(2),
          });
        }
        else if ((key === "plannedEnd" || key === "plannedStart") && value && rowData && rowData["changedBy"] === "USER") {
          //  Parse date in "DD-MM-YYYY" format
          const parsedDate = moment(value, "DD-MM-YYYY", true);
          if (!parsedDate.isValid()) {
            console.error(`Invalid date format: ${value} for ${key} (unitActivityId: ${unitActivityId})`);
            return;
          }

          const formattedDate = parsedDate.utc().format("YYYY-MM-DD HH:mm:ss.SSS[Z]");

          //  Check if an entry already exists for this unitActivityId
          if (!plannedUpdates[unitActivityId]) {
            plannedUpdates[unitActivityId] = {
              projectId: store.projectInfo.currentProject?.id,
              unitActivityId,
            };
          }

          //  Assign the date to either planStart or planEnd
          if (key === "plannedStart") {
            plannedUpdates[unitActivityId].planStart = formattedDate;
          } else if (key === "plannedEnd") {
            plannedUpdates[unitActivityId].planEnd = formattedDate;
          }

        }
      });
    });

    try {
      const plannedUpdatesArray = Object.values(plannedUpdates); // Convert object into array
      if (plannedUpdatesArray.length > 0) {
        await Promise.all(plannedUpdatesArray.map(update => unitActivityUpdate({ payload: update })));
      }

      if (scheduleList.length > 0) {
        await upsertTargets({ projectId: store.projectInfo.currentProject?.id, scheduleList });
      }

      setIsSaveButtonDisabled(true); //  Disable Save button after successful save
      await loadData(); //  Reload the data to reflect changes
    } catch (error) {
      console.error("Failed to save changes:", error);
    }
  };


  const handleReCalculate = async () => {
    const projectId = store.projectInfo.currentProject?.id || '';
    const targetPeriod = `${year}-${month.toString().padStart(2, '0')}`;
    setCalculationLoading(true);
    try {
      await reCalculateTargets({ projectId, targetPeriod });
      loadData();
      setCalculationLoading(false);
    } catch (error) {
      console.log('handleReCalculate error', error)
      setCalculationLoading(false);
    }
  }

  const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    value: `${i + 1}`,
    label: new Date(0, i).toLocaleString('en', { month: 'long' }),
  }));

  const yearOptions = Array.from({ length: 3 }, (_, i) => {
    const year = currentYear - 1 + i;
    return {
      value: `${year}`,
      label: `${year}`,
    };
  });

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <h3 className={classes.title}>Targets by Day</h3>
        <div className={classes.title} style={{ minWidth: 190, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}><Avatar color="red" size={'2em'}>T</Avatar><span style={{ marginLeft: '0.2em' }}>Target</span></div>
          <div style={{ display: 'flex', alignItems: 'center' }}><Avatar color="teal" size={'2em'}>A</Avatar><span style={{ marginLeft: '0.2em' }}>Achieved</span></div>
        </div>
      </div>
      <div className={classes.dropdownDiv}>
        {/* Dropdowns on the left */}
        <div className={classes.dropdownsContainer}>
          <Select
            label="Choose Year"
            value={String(year)}
            onChange={(value) => setYear(Number(value))}
            data={yearOptions}
            placeholder="Select year"
            styles={{ dropdown: { maxWidth: 200 } }}
            className={classes.dropdown}
            sx={{
              ["& .mantine-Select-input"]: { borderRadius: 0 }
            }}
          />
          <Select
            label="Choose Month"
            value={String(month)}
            onChange={(value) => setMonth(Number(value))}
            data={monthOptions}
            placeholder="Select month"
            styles={{ dropdown: { maxWidth: 200 } }}
            className={classes.dropdown}
            sx={{
              ["& .mantine-Select-input"]: { borderRadius: 0 }
            }}
          />
        </div>

        {/* Buttons on the right */}
        <div className={classes.buttonsContainer}>
          {
            (dailyPlanningmode !== DailyPlanningModes.SYSTEM) ?
              <Button
                onClick={handleSaveChanges}
                className={classes.saveButton}
                disabled={isSaveButtonDisabled}
              >
                Save <IconDeviceFloppy width={'1em'} height={'1em'} style={{ marginLeft: '0.25em' }} />
              </Button>
              : null
          }
          <Button
            onClick={loadData}
            className={classes.saveButton}
          >
            Refresh <IconRefresh width={'1em'} height={'1em'} style={{ marginLeft: '0.25em' }} />
          </Button>
          {
            (dailyPlanningmode === DailyPlanningModes.PLANNER) ?
              <Button
                onClick={handleReCalculate}
                className={classes.saveButton}
                disabled={calculationLoading}
              >
                Re-Calculate Targets <IconTargetArrow width={'1em'} height={'1em'} style={{ marginLeft: '0.25em' }} />
              </Button>
              : null
          }
        </div>
      </div>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <LoadingSpinner />
        </div>
      ) : !data.length ? (
        <NoDataForTargets />
      ) : (
        <div style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
          <DataGrid key={`${year}-${month}`} data={data} columns={columns} onChange={handleGridChange} reportType={'daily'} currentMonth={month} />
        </div>
      )}
    </div>
  );
};

export default observer(DailyTargets);
