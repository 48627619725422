import { types, flow, Instance, getRoot } from 'mobx-state-tree';
import { getFeatureConfig2 } from '../api/transactionServer';
import { DefaultAccessConfig } from './enums';
import { LateStoreModel } from './DataStore';

export const Features = types.model('Features', {
    accessConfig: types.optional(types.frozen(), DefaultAccessConfig),
}).actions((self) => ({
    setAccessConfig(configFromAPI: any) {
        self.accessConfig = { ...DefaultAccessConfig, ...configFromAPI };
    },
}))
    .actions((self) => ({
        fetchAccessConfig: flow(function* () {
            try {
                const root = (getRoot(self) as Instance<typeof LateStoreModel>)
                const res = yield getFeatureConfig2({ tenant: root.auth.customerId, payload: { featureName: 'bulkUpload', projectIdList: [root.projectInfo.currentProject!.id] } })
                if (res?.data) {
                    // console.log('res ponse', res.data[root.projectInfo.currentProject!.id]['bulkUpload'])
                    self.setAccessConfig(res.data[root.projectInfo.currentProject!.id]['bulkUpload']);
                    // localStorage.setItem('accessConfig', JSON.stringify({ ...DefaultAccessConfig, ...res.data[root.projectInfo.currentProject!.id]['bulkUpload'] }))
                }
            } catch (error) {
                console.error('Failed to fetch access config', error);
            }
        }),
    }));


export interface IFeaturesModel extends Instance<typeof Features> { }