import React from 'react';
import { Tooltip, Flex, ActionIcon, Select, Button,  } from '@mantine/core';
import { IconRefresh, IconBuilding, IconTableColumn } from '@tabler/icons-react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../models/ProvideModel';
import useStyles from './ActivityManagerStyles';

const ActivityManagerToolbar = () => {
  const classes = useStyles();
  const store = useStore();
  return (
    <Flex
      gap="8px"
      direction="column"
      sx={{ flexGrow: 1, }}
    >
      <Flex
        justify="flex-start"
        align="flex-end"
        mr="1rem"
        mt="1rem"
        sx={{ flexGrow: 1, }}>
        <Flex >
          <span style={{
            fontSize: "1.7em",
            display: "flex",
            justifyContent: "flex-start",
            fontWeight: 700,
            color: "#2F3E6C",
            // flexGrow: 2,
          }}>Activity Manager &nbsp; <span style={{ fontSize: '0.7em', color: '#C9C9C9', alignSelf: 'center' }}>{`(${store.activityManager.filteredRowsCount})`}</span></span>
          <Tooltip label={"Refresh Unit-Activities For Current Selection"}>
            <ActionIcon
              ml="1em"
              mr="1em"
              className={classes.refreshTasks}
              onClick={() => {
                store.activityManager.refreshUnitActivities()
              }}
            >
              <IconRefresh />
            </ActionIcon>
          </Tooltip>
        </Flex>
        {store.activityManager.dropdownsSelected ?
         (
           <Flex justify="flex-start" align="flex-end">
                 <Select
                   label="Block"
                   placeholder="Pick a Block"
                   searchable
                   nothingFound="No Matching Blocks"
                   maxDropdownHeight={280}
                   data={store.activityManager.dropdowns.get("blocks")?.options.map(({ id, name }) => ({ label: name, value: id })) || []}
                   onChange={val => { store.activityManager.selectBlock(val); }}
                   value={store.activityManager.selectedBlock}
                   icon={<IconBuilding size="1rem" />}
                 />
                 <Select
                   label="Location Type"
                   placeholder="Pick a Location Type"
                   searchable
                   nothingFound="No Matching Location Types"
                   maxDropdownHeight={280}
                   data={store.activityManager.dropdowns.get("unitTypes")?.options.map(({ id, name }) => ({ label: name, value: id })) || []}
                   onChange={val => { store.activityManager.selectUnitType(val); }}
                   value={store.activityManager.selectedUnitType}
                   icon={<IconTableColumn size="1rem" />}
                 />
                 <Button
                   variant="filled"
                   aria-label="Get Unit Activities"
                   disabled={store.activityManager.doesCallParamsDataMatch}
                   size="sm"
                   sx={{ alignSelf: "flex-end" }}
                   ml=".2rem"
                   onClick={() => { store.activityManager.getUnitActivities(); }}>
                   Load
                 </Button>
           </Flex>
         )
        : null
        }
      </Flex>
    </Flex >
  );
};

export default observer(ActivityManagerToolbar);
