import React from 'react';
import { MRT_ColumnDef, MRT_TableOptions } from 'mantine-react-table';
import ActivityStatus from '../../components/requests/cells/ActivityStatus';
import { UnitActivityDetails } from '../planningModulePage/gridColumns';


/* const __floorNumSort = sortingStrategyPosition("floorIdx", "floorLabel");
 * const floorSortFn: MRT_SortingFn<any> = (rowA, rowB, _) => __floorNumSort(rowA.original, rowB.original);
 * 
 * const CustomHeader = ({ column }) => <em>{column.columnDef.header}</em>; */


export const uaColumnsDef: MRT_ColumnDef<UnitActivityDetails>[] =
  [
    {
      accessorKey: 'blockName',
      header: 'Block',
      size: 60,
      /* maxSize: 300, */
      enableClickToCopy: false,
      enableColumnFilterModes: false,
      /* enableEditing: false, */
      filterVariant: 'multi-select',
    },
    {
      accessorKey: 'floorLabel',
      header: 'Floor',
      size: 40,
      /* maxSize: 300, */
      enableClickToCopy: false,
      filterVariant: 'multi-select',
      enableColumnFilterModes: false,
    },
    {
      accessorKey: 'unitTitle',
      header: 'Location',
      /* size: 300, */
      /* maxSize: 300, */
      enableClickToCopy: false,
      enableColumnFilterModes: false,
    },
    /* {
     *   accessorKey: 'activityTypeTitle',
     *   enableClickToCopy: false,
     *   header: 'Activity Type',
     *   size: 100,
     *   filterVariant: 'multi-select',
     *   enableColumnFilterModes: false,
     * }, */
    {
      accessorKey: 'title',
      header: 'Activity Title',
      size: 300,
      /* maxSize: 300, */
      enableClickToCopy: false,
      enableColumnFilterModes: false,
    },
    {
      accessorKey: 'statusName',
      enableClickToCopy: false,
      header: 'Status',
      size: 50,
      filterVariant: 'multi-select',
      editVariant: 'select',
      enableColumnFilterModes: false,
      Cell: ({ row }) => <ActivityStatus row={row.original.unitActivity} />,
    },
    {
      accessorKey: 'unitTypeName',
      enableClickToCopy: false,
      header: 'Location Type',
      size: 80,
      filterVariant: 'multi-select',
      enableColumnFilterModes: false,
    },
  ];

export const tableOptions: Partial<MRT_TableOptions<any>> = {
  enablePagination: true,
  /* paginationDisplayMode: "pages", */
  positionPagination: "bottom",
  autoResetPageIndex: false,
  initialState: {
    pagination: { pageSize: 100, pageIndex: 0, },
    showColumnFilters: true, density: 'xs',
  },
  mantinePaperProps: () => ({
    sx: {
      padding: '0em 1em',
      display: "flex",
      flexDirection: "column",
      width: "100%",
    }
  }),
};

export const gridKeys = [
  "id",
  "blockId",
  "blockName",
  "title",
  "unitId",
  "unitTitle",
  /* "activityTypeId",
   * "activityTypeTitle", */
  /* "planStart",
   * "planEnd", */
  "statusId",
  "statusName",
  /* "canEdit", */
  "unitTypeId",
  "unitTypeName",
  /* "plannedTotalQuantity" */
  "floorIdx",
  "floorLabel",
];
