import { types, flow, getRoot, Instance, applyPatch, resolveIdentifier, applySnapshot, } from 'mobx-state-tree';
import { commonFetch, unitActivityStart, } from '../api/transactionServer';
import { LateStoreModel } from './DataStore';
import moment from 'moment';
// import { notifications } from '@mantine/notifications';
import { ActivityStatuses, CommonApiEntities, getEntityDropdownType, } from './enums';
import { activityStatuses } from '../utils/constants';
import { Dropdown, GeneralDropdown } from "./Planning";

const __limit = 1000000;
const sortBySyncCTFunc = (a: any, b: any) => { return moment(b.syncCt).valueOf() - moment(a.syncCt).valueOf(); };

const UnitActivity = types.model({
    id: types.identifier,
    title: types.optional(types.string, ""),
    blockId: types.string,
    blockName: types.string,
    unitId: types.string,
    unitTitle: types.string,
    activityTypeId: types.string,
    activityTypeTitle: types.string,
    statusId: types.string,
    // statusName: types.optional(types.string, "Status"),
    // TODO: maybe number?
    progress: (types.optional, types.number, 0),
    // progress: (types.optional, types.string, "0"),
    unitTypeId: types.string,
    isEditing: false,
    unitTypeName: types.optional(types.string, ""),
    floorIdx: types.optional(types.number, -1000),
    floorLabel: types.optional(types.string, "NA"),
}).views(self => ({
    get statusName() {
        //TODO: Implement
        return activityStatuses[self.statusId] || "Invalid";
    },
    get canEdit() {
        //TODO: eventually put your permissions logic here.
        return true;
    }
})).actions(self => ({
    startActivity: flow(function* startActivity() {
        if (!self.isEditing && (self.statusId === ActivityStatuses.PLANNED || self.statusId === ActivityStatuses.CONFIGURED)) {
            try {
                const root = (getRoot(self) as Instance<typeof LateStoreModel>);
                //TODO: Replace API call here
                // yield Promise.resolve();
                yield unitActivityStart({ projectId: root.projectInfo.currentProject!.id, unitActivityId: self.id });
                applyPatch(self, { op: "replace", path: `/statusId`, value: ActivityStatuses.ACTIVE });
            }
            catch (err) { console.error(err); }
        }
    }),
}));
export const ActivityManager = types.model({
    loading: false,
    unitActivities: types.array(UnitActivity),
    gridKeys: types.array(types.string),
    filteredRowsCount: types.optional(types.number, 0),
    dropdowns: types.map(Dropdown),
    dropdownFields: types.array(types.string),
    selectedBlock: types.maybeNull(types.string),
    selectedUnitType: types.maybeNull(types.string),
    callParams: types.array(types.string),
    dropdownLoading: false,
}).views(self => ({
    get projectId() {
        // TODO: remove harcoded projectId.
        // return "project_1";
        return (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id;
    },
})).views(self => ({
    get canStart() {
        //TODO: Implement Permissions here.
        return true;
    },
    get unitActivitiesGrid() {
        if (!(self.gridKeys.length && self.unitActivities.length)) { return []; }
        return self.unitActivities.map(ua =>
            self.gridKeys.reduce((acc, k) => ({
                ...acc,
                [k]: Array.isArray(ua[k]) ? [...ua[k]] : ua[k]
            }), { unitActivity: ua }));
    },
    get dropdownsSelected() {
        return !!self.selectedBlock && !!self.selectedUnitType;
    },
})).views(self => ({
    get doesCallParamsDataMatch() {
        if (!self.callParams.length) { return false; }
        return self.dropdownsSelected && (self.callParams[0] === self.selectedBlock && self.callParams[1] === self.selectedUnitType);
    },
})).actions(self => ({
    addGridKeys(val: string[]) {
        self.gridKeys.push(...val);
    },
    setFilterRowsCount(val: number) {
        self.filteredRowsCount = val;
    },
    selectBlock(id: string) {
        if (id === self.selectedBlock) {
            console.log("skipping redundant block selection");
            return;
        }
        self.selectedBlock = id;
    },
    selectUnitType(id: string) {
        if (id === self.selectedUnitType) {
            console.log("skipping redundant unit type selection");
            return;
        }
        self.selectedUnitType = id;
    },
    setDropdown(id, type, options) {
        self.dropdowns.set(id, { id, type, options });
    },
    setDropdownFields(val: string[]) {
        self.dropdownFields.replace(val);
    },
})).actions(self => ({
    getUnitActivities: flow(function* getUnitActivities() {
        if (!self.dropdownsSelected) { return; }
        self.loading = true;
        self.unitActivities.clear();
        if (!self.doesCallParamsDataMatch) { self.callParams.clear(); self.callParams.push(self.selectedBlock!, self.selectedUnitType!); }
        try {
            const { data } = yield commonFetch({ projectId: self.projectId, entity: CommonApiEntities.UNIT_ACTIVITIES, filters: { isDPR: "false", statuses: [ActivityStatuses.PLANNED, ActivityStatuses.CONFIGURED], blockIds: self.selectedBlock, unitTypes: self.selectedUnitType } });
            const list = (data || []).length > __limit ? (data || []).slice(0, __limit).sort(sortBySyncCTFunc) : (data || []).sort(sortBySyncCTFunc);
            self.unitActivities.replace(list);
            // applySnapshot(self.unitActivities, list);
        }
        catch (err) { console.error(err); }
        finally { self.loading = false; }
    }),
    refreshUnitActivities: flow(function* getUnitActivities() {
        if (!self.callParams.length) { return; }
        self.loading = true;
        self.unitActivities.clear();
        if (!self.doesCallParamsDataMatch) { self.selectedBlock = self.callParams[0]!; self.selectedUnitType = self.callParams[1]!; }
        try {
            const { data } = yield commonFetch({ projectId: self.projectId, entity: CommonApiEntities.UNIT_ACTIVITIES, filters: { isDPR: "false", statuses: [ActivityStatuses.PLANNED, ActivityStatuses.CONFIGURED], blockIds: self.callParams[0]!, unitTypes: self.callParams[1]! } });
            const list = (data || []).length > __limit ? (data || []).slice(0, __limit).sort(sortBySyncCTFunc) : (data || []).sort(sortBySyncCTFunc);
            self.unitActivities.replace(list);
            // applySnapshot(self.unitActivities, list);
        }
        catch (err) { console.error(err); }
        finally { self.loading = false; }
    }),
    getDropdownValue: flow(function* getDropdownValue(id, type) {
        try {
            self.dropdowns.get(id)?.options.clear();
            const { data } = yield commonFetch(id === CommonApiEntities.FLOORS ? { projectId: self.projectId, entity: CommonApiEntities.UNITS, filters: { unitType: "floor" } } : { projectId: self.projectId, entity: id });
            if (id === "floors") {
                self.setDropdown(id, type, data.map(obj => ({ id: obj.floorIdx, name: obj.floorLabel, blockId: obj.blockId })));
            }
            else if (["unitTypes", "units"].includes(id)) {
                // self.setDropdown(id, type, data.filter(obj => !["block", "floor"].includes(id === "unitTypes" ? obj.id : obj.unitType)))
                self.setDropdown(id, type, data)
            }
            else {
                self.setDropdown(id, type, data);
            }
        }
        catch (err) { console.error(err); }
    }),
})).actions(self => ({
    getDropdownValues() {
        self.dropdownFields.forEach(id => {
            const type = getEntityDropdownType(id);
            self.getDropdownValue(id, type);
        });
    },
}));

export interface IActivityManager extends Instance<typeof ActivityManager> { };
export interface IUnitActivity extends Instance<typeof UnitActivity> { };
