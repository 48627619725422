import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
/* import { useLocation, useHistory } from 'react-router'; */
import { useStore } from '../../models/ProvideModel';
import LoadingTowers from '../../components/LoadingTowers';
import RequestTable from '../../components/requests/MantineTable';
import { gridKeys, tableOptions, uaColumnsDef as columnsDef, } from './amGridColumns';
import ActivityManagerToolbar from './ActivityManagerToolbar';
import useStyles from './ActivityManagerStyles';
import { Button, Flex, Select, Title, ThemeIcon } from '@mantine/core';
import { IconBuilding, IconHttpGet, IconTableColumn } from '@tabler/icons-react';

/* const __module = "activityManager"; */
/* const __dropdownFields = ['units', 'activityTypes', 'unitTypes', 'blocks', 'floors']; */
const __dropdownFields = ['blocks', 'unitTypes',];

const rowCallback = () => { };
const PlanningModule = () => {
  const store = useStore();
  const classes = useStyles();
  /* const { search } = useLocation();
   * const { push } = useHistory();
   * const searchParams = useRef<URLSearchParams>(new URLSearchParams(search)); */
  const ToolbarComponent = useMemo(() => () => <ActivityManagerToolbar />, []);
  useEffect(() => {
    store.activityManager.addGridKeys(gridKeys);
    store.activityManager.setDropdownFields(__dropdownFields);
    store.activityManager.getDropdownValues();
    /* store.activityManager.getUnitActivities(); */
  }, []);
  const getFilteredRowCount = useCallback((rowCount: number) => {
    store.activityManager.setFilterRowsCount(rowCount);
    /* setFilteredRowsCount(rowCount) */
  }, []);
  return (
    <div className={classes.root}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
        <div style={{ display: "flex", height: "100%", width: "100%", flexGrow: 1, flexShrink: 1 }}>
          {store.activityManager.loading ?
            (<LoadingTowers title={"Fetching UnitActivities"} />) :
            !store.activityManager.callParams.length ?
              (
                <Flex justify="flex-start" align="center" w="100%" direction="column">

                  <Flex justify="flex-end" align="flex-start" mt="3rem">
                    <Select
                      label="Block"
                      placeholder="Pick a Block"
                      searchable
                      nothingFound="No Matching Blocks"
                      maxDropdownHeight={280}
                      data={store.activityManager.dropdowns.get("blocks")?.options.map(({ id, name }) => ({ label: name, value: id })) || []}
                      onChange={val => { store.activityManager.selectBlock(val); }}
                      icon={<IconBuilding size="1rem" />}
                    />
                    <Select
                      label="Location Type"
                      placeholder="Pick a Location Type"
                      searchable
                      nothingFound="No Matching Location Types"
                      maxDropdownHeight={280}
                      data={store.activityManager.dropdowns.get("unitTypes")?.options.map(({ id, name }) => ({ label: name, value: id })) || []}
                      onChange={val => { store.activityManager.selectUnitType(val); }}
                      icon={<IconTableColumn size="1rem" />}
                    />
                    <Button
                      size="sm"
                      disabled={!store.activityManager.dropdownsSelected}
                      onClick={() => store.activityManager.getUnitActivities()}
                      sx={{ alignSelf: "flex-end" }}
                      ml=".4rem"
                      variant="filled"
                    >
                      Load
                    </Button>
                  </Flex>
                  <Flex align="center" mih="30%" >
                    <Title ta="center" order={3}>
                      Please select a <ThemeIcon variant="outline" sx={{ verticalAlign: "text-bottom" }}><IconBuilding stroke={1.5} /></ThemeIcon> Block and a <ThemeIcon variant="outline" sx={{ verticalAlign: "text-bottom" }}> <IconTableColumn size="1rem" /> </ThemeIcon> Location Type
                    </Title>
                  </Flex>
                </Flex>
              )
              :
              (
                <RequestTable
                  data={store.activityManager.unitActivitiesGrid}
                  rowCallback={rowCallback}
                  setRowCount={getFilteredRowCount}
                  columns={columnsDef}
                  ToolbarComponent={ToolbarComponent}
                  tableOptions={tableOptions}
                  useFilterListeners={() => { }}
                >
                </RequestTable>

              )
          }
        </div>
      </div>
    </div >
  );

};

export default observer(PlanningModule);
