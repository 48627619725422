import React, { useMemo } from "react";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { MRT_TableOptions } from "mantine-react-table";
import { Instance } from "mobx-state-tree";
import { LateStoreModel } from "../../../models/DataStore";
import { IconEdit } from "@tabler/icons-react";
import { CommonApiEntities } from "../../../models/enums";


export const useTableOptions: (store: Instance<typeof LateStoreModel>) => Partial<MRT_TableOptions<any>> = store => useMemo(() => ({
    creatingMode: 'row',
    editingMode: 'row',
    enableEditing: true,
    onCreatingRowCancel: () => { store.configManager.resetEditingRow(); },
    onCreatingRowSave: async ({ values, table }) => {
        try {
            await store.configManager.createEntityItem(CommonApiEntities.PROPERTIES, { ...values, propertyId: values.id, floors: values.floors > 0 ?  values.floors : 0 });
            table.setCreatingRow(null); // clear creation mode
        } catch (error) {
            console.error("Property creation failed:", error);
        }
    },
    onEditingRowCancel: () => { store.configManager.resetEditingRow(); },
    onEditingRowSave: async ({ row, table, values }) => {
        try {
            const changed = store.configManager.currentlyEditingRow;

            if (changed && Object.keys(changed).length > 0) {
                await store.configManager.updateEntityItem(CommonApiEntities.PROPERTIES, {
                    ...row.original,
                    ...changed,
                    propertyId: row.original.id,
                    customerId: store.configManager.currentCustomer
                });
                store.configManager.resetEditingRow();
            }

            table.setEditingRow(null); // Exit editing mode
        } catch (error) {
            console.error("Property modification failed:", error);
        }
    },
    displayColumnDefOptions: {
        'mrt-row-actions': {
            header: 'Edit', //change "Actions" to "Edit"
        },
    },
    renderRowActions: ({ row, table }) => (
        <Flex gap="md">
            <Tooltip label="Edit">
                <ActionIcon onClick={() => { table.setEditingRow(row); }}>
                    <IconEdit />
                </ActionIcon>
            </Tooltip>
        </Flex>
    ),
    positionActionsColumn: 'first',
    enablePagination: true,
    paginationDisplayMode: "pages",
    positionPagination: "bottom",
    autoResetPageIndex: false,
    enablePinning: true,
    initialState: {
        columnPinning: { left: ['mrt-row-actions'], },
        pagination: { pageSize: 100, pageIndex: 0, },
        showColumnFilters: true, density: 'xs',
        columnVisibility: { 'mrt-row-actions': true },
    },
    mantinePaperProps: () => ({
        sx: {
            padding: '0em 1em',
            display: "flex",
            flexDirection: "column",
            width: "100%",
        }
    }),
})
    , [])