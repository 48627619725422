import React from 'react';
import { CloseButton, Group, Tabs } from '@mantine/core';
import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { IBulkImport } from '../../models/Planning';
import BulkCreate from './create/CreateActivities';
import BulkUpdate from './update/UpdateActivities';
import { useStore } from '../../models/ProvideModel';

const ActivityExcelImport = ({ importModel, onClose }: { importModel: IBulkImport, onClose(): void; }) => {
    const store = useStore();
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Group position='apart' w="100%" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography title='BulkImport' variant='h5' style={{ padding: '0.5em', fontWeight: 'bold' }}>Bulk Import</Typography>
                <CloseButton color='red' title='Close' onClick={onClose} style={{ marginRight: '0.5em' }} />
            </Group>

            <Tabs defaultValue={store.features.accessConfig.dpr.create ? "create" : "update"} style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                <Tabs.List>
                    {store.features.accessConfig.dpr.create && <Tabs.Tab value="create" >Create</Tabs.Tab>}
                    {store.features.accessConfig.dpr.update && <Tabs.Tab value="update" >Modify</Tabs.Tab>}
                    <Tabs.Tab value="msproject" disabled>MS-Project upload</Tabs.Tab>
                </Tabs.List>

                {store.features.accessConfig.dpr.create && <Tabs.Panel value="create" pt="xs" style={{ overflow: 'auto' }}>
                    <BulkCreate importModel={importModel} />
                </Tabs.Panel>}

                {store.features.accessConfig.dpr.update && <Tabs.Panel value="update" pt="xs" style={{ overflow: 'auto' }}>
                    <BulkUpdate importModel={importModel} />
                </Tabs.Panel>}

                {/* <Tabs.Panel value="msproject" pt="xs" style={{ overflow: 'auto' }}>
                    <div>Coming soon:</div>
                    <div>MS - project import</div>
                </Tabs.Panel> */}
            </Tabs>
        </div>
    )
}

export default observer(ActivityExcelImport);