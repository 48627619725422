import React, { RefObject, useState } from 'react'
import { downloadFile, uploadFileV2, submitAttachmentComment } from '../../../../api/transactionServer';
import { IconDownload, IconPlus, IconFile, IconX } from '@tabler/icons-react';
import moment from 'moment';
import { Loader, Tooltip, Card, Image, Text, Badge, Button, Group, Box, SimpleGrid, Switch, CloseButton, LoadingOverlay, Notification } from '@mantine/core';
import useStyles from './AttachmentStyles'
import { Fab, Grid, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import GenericModal from '../../../GenericModal';
import { getType, getFileExtension } from '../../../../utils/utils';
import { Dropzone } from '@mantine/dropzone';
import { ITicket } from '../../../../models/Ticket';
import { notifications } from '@mantine/notifications';

export interface IAttachment {
  customerId: string;
  filesList: IFile[]
  openRef: RefObject<() => void>
  usersMap: { [K: string]: string }
  loading: boolean;
  activeTab?: string | null;
  ticket: ITicket;
  userId: string;
  projectId: string;
}

export interface IFile {
  fileId: string
  fileName: string,
  createdAt?: any,
  createdBy?: any,
  fileType: string,
  version: number
}

const GetFileType = ({ fileName, classes }) => {
  return (
    <div className={classes.fileTypeTextDiv}>
      <IconFile className={classes.fileIcon} />
      <span className={classes.fileTypeText}>{(getFileExtension(fileName) || 'unknown').toUpperCase()}</span>
    </div>
  )
}


const isImageType = (fileType) => (getType(fileType) == "jpeg" || getType(fileType) == "jpg" || getType(fileType) == "png")

const FileCardView = ({ filesList, usersMap, classes, onDownload, setToggle, customerId }) => {
  return (
    <div>
      <SimpleGrid cols={3}>
        {filesList && filesList.map(img => (
          <Card key={img.fileId} shadow="sm" padding="lg" radius="sm" withBorder>
            <Card.Section>
              {(isImageType(img.fileType)) ?
                <>
                  <Image
                    className={`${classes.yourComponent} ${classes.imageHoverEffect}`} onMouseOver={(e) => e.currentTarget.classList.add(classes.viewText)} onMouseOut={(e) => e.currentTarget.classList.remove(classes.viewText)}
                    src={`${process.env.REACT_APP_TRANSACTION_SERVER_URL}files/download/v3?customerName=${customerId}&fileId=${img.fileId}&versionId=${img.version || 0}`}
                    height={100}
                    alt="Norway"
                    withPlaceholder
                    onClick={() => setToggle(() => [`${process.env.REACT_APP_TRANSACTION_SERVER_URL}files/download/v3?customerName=${customerId}&fileId=${img.fileId}&versionId=${img.version || 0}`])}
                  />
                </>
                : <GetFileType fileName={img.fileName} classes={classes} />}
              {/* <GetFileType fileType={img.fileType} classes={classes}/> */}
            </Card.Section>

            <Group position="apart" mt="md" mb="xs" sx={{ ['. &mantine-Group-root']: { marginTop: 0 } }}>
              <Text sx={{ fontSize: '0.8em' }} weight={500} className={classes.fileName}>{img.fileName}</Text>
            </Group>

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', fontSize: '0.7em' }}>
                <span style={{ width: '25%', opacity: '0.5' }}>Author: </span>
                <span style={{ width: '75%', textAlign: 'right' }}>{usersMap[img.createdBy] || "-"}</span>
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', fontSize: '0.6em' }}>
                <span style={{ opacity: '0.5' }}>Created on: </span>
                <span>{moment(img.createdAt).format("DD MMM YYYY hh:mm A")}</span>
              </div>
            </div>

            <Button variant="light" color="blue" fullWidth mt="md" radius="sm" onClick={() => onDownload(img.fileId, img.fileName)}>
              <span style={{ fontSize: '0.9em' }}>Download</span>
            </Button>
          </Card>
        ))}
      </SimpleGrid>
    </div>
  );
}

const MAX_SIZE = 100 * 1024 ** 2;

const Attachments = ({ filesList, usersMap, openRef, loading, customerId, ticket, userId, projectId }: IAttachment) => {

  const classes = useStyles();
  const [showCardView, setShowCardView] = useState(true);
  const [toggle, setToggle] = useState<string[]>(['']);
  const [rejectMessage, setRejectMessage] = React.useState("");

  React.useEffect(() => {
    if (rejectMessage) {
      notifications.show({
        title: 'Error!',
        message: rejectMessage,
        color: 'red',
        icon: <IconX />
      })
    }
  }, [rejectMessage]);

  const [fileLoading, setFileLoading] = React.useState(false);
  const onDownload = (fileId: string, fileName: string) => {
    downloadFile({ fileId, fileName });
  };

  const closeAssigneeListModal = () => { setToggle(() => ['']) }
  const onDownloadAll = () => {
    return Promise.all(filesList.map(file => downloadFile({ fileId: file.fileId, fileName: file.fileName })))
  }
  // console.log('openRef', openRef)

  const renderAttachmentDetails = (array: any) => {
    let message = ""
    array.map((file) => message += `<strong>${file.fileName}</strong><br/>`)
    return message
  }

  const onChange = (dzfiles) => {
    let allAttachments: IFile[] = [];
    setFileLoading(true);
    return Promise.all(dzfiles.map(file => uploadFileV2({ file }).then(res => ({ ...res, file })))).then((list) => {
      const allAttachments: IFile[] = list.map(({ data }) => data);
      submitAttachmentComment({ processInstanceId: ticket.id, userId, commentType: 'media', commentMessage: `${renderAttachmentDetails(allAttachments)}`, customerId, projectId, moduleId: "requests", attachments: allAttachments })
        .then(() => ticket.refreshComments())
        .then(() => setFileLoading(false));
    }).catch(error => { console.error(error); setFileLoading(false) })
  }

  const onDropHandler = (files: any[]) => {
    setRejectMessage("");
    let largeFile = false;
    files.map(file => { if (file.size > MAX_SIZE) { largeFile = true } });
    if (!largeFile) { onChange(files); } else { setRejectMessage(`Maximum file upload size is 100 MB.`) }
  }

  return (
    <div className={classes.root}>
      <div style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%', background: '#F1F3F5', boxShadow: "0px 10px 10px -5px rgba(0,0,0,0.12)", }}>
        <div className={classes.head}>
          <div className={classes.title}>Attachments {`(${filesList.length})`}
          </div>
          {(!loading && !filesList.length) ? <span className={classes.noAttach}>{'No attachments'}</span> : loading ? <span className={classes.noAttach}>{'Fetching files...'}</span> : null}
          {(!loading && filesList.length) ? <span className={classes.downloadText} onClick={onDownloadAll}><IconDownload style={{ marginRight: '0.2em' }} className={classes.tablerIcon} /> Download All</span> : null}
          {(!loading && filesList.length) ? <Switch labelPosition='left' size={'xs'} className={classes.switch} checked={showCardView} onChange={() => setShowCardView(!showCardView)} label={showCardView ? 'Tiles' : 'List'} /> : null}
        </div>
      </div>
      <div className={classes.itemContainer} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        {fileLoading && (
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255, 255, 255, 0.7)', zIndex: 2 }}>
            <Loader variant='dots' size={'md'} color='#585858' />
          </div>
        )}
        <Dropzone
          openRef={openRef}
          onDrop={onDropHandler}
          onReject={(files) => { console.log('rejected files', files); }}
          activateOnClick={false}
          multiple={true}
          className={classes.dropzoneRoot}
          style={{ padding: '0', height: '100%', width: '100%', overflow: 'overlay', }}
          styles={{ inner: { pointerEvents: "all", cursor: "default", display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', backdropFilter: 'blur(5px)' }, }}
        >
          {
            showCardView ?
              <div className={classes.itemCardScrollLayer} style={{ height: '100%', width: '100%' }}>
                {(!filesList.length && !loading) ?
                  <Group position="center" spacing="xs" style={{ pointerEvents: 'none', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                    <Text size="md" inline>
                      Drag files here or click + below to select files
                    </Text>
                    <Text size="xs" color="dimmed" inline mt={7}>
                      Attach as many files as you like, each file should not exceed 100 MB
                    </Text>
                  </Group> :
                  <FileCardView filesList={filesList} usersMap={usersMap} classes={classes} onDownload={onDownload} setToggle={setToggle} customerId={customerId} />
                }
              </div>
              :
              <div className={classes.itemScrollLayer} style={{ height: '100%', width: '100%' }}>
                {
                  loading ? <div className={classes.item} style={{ width: '100%', justifyContent: 'center', alignSelf: 'center', height: '100%' }}><Loader variant='dots' size={'xs'} color='#585858' /></div> :
                    filesList.length ? filesList.map((file, idx) => (

                      <Paper key={idx} style={{ padding: "10px", marginBottom: "10px", width: '100%' }}>
                        <Grid container spacing={2} style={{ height: '100%' }}>
                          <Grid item xs={3} className={classes.cell}>
                            <span className={classes.cellHead}>Name</span>
                            <Typography noWrap className={classes.cellBody}>{file.fileName}</Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.cell}>
                            <span className={classes.cellHead}>Modified On</span>
                            <Typography noWrap className={classes.cellBody}>{moment(file.createdAt).format("DD MMM YYYY hh:mm A")}</Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.cell}>
                            <span className={classes.cellHead}>Author</span>
                            <Typography noWrap className={classes.cellBody}>{usersMap[file.createdBy] || "-"}</Typography>
                          </Grid>
                          <Grid item xs={3} className={classes.cell}>
                            <span className={classes.cellHead}>Actions</span>
                            <Typography noWrap className={classes.cellBody}><IconDownload className={classes.tablerIcon} onClick={() => onDownload(file.fileId, file.fileName)} /></Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    )) : null}
              </div>}
        </Dropzone>
      </div>
      <div style={{ display: 'flex', position: 'sticky', bottom: 0, padding: '1em', justifyContent: 'flex-end' }}>
        {/* <Button className={classes.addButton} variant='outline' onClick={() => { if (openRef.current) openRef?.current() }}>Add New</Button> */}
        <Tooltip label={"Add new"}>
          <Fab aria-label="add"
            size='small'
            onClick={() => { if (openRef.current) openRef?.current() }}
            style={{ background: "#005eff" }}
          ><IconPlus color='#FFFFFF' style={{ width: '1.2em', height: '1.2em' }} /></Fab>
        </Tooltip>
      </div>
      <GenericModal
        isOpen={toggle[0] !== '' ? true : false}
        onClose={closeAssigneeListModal}
        actions={[]}
      >
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#FFF', position: 'sticky', top: 0, zIndex: 1, padding: '0.8em' }}>
            <span style={{ color: '#585858', fontWeight: 600 }}>Image preview</span>
            <span><CloseButton color='red' title='Close' onClick={closeAssigneeListModal} /></span>
          </div>
          <Image
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
            src={toggle[0]}
            alt="Norway"
            withPlaceholder
          />
          {/* <img src={toggle[0]}/> */}
        </div>
      </GenericModal>

    </div>
  )
}

export default observer(Attachments);
