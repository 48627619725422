import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../models/ProvideModel";
import DataGrid from "../../../../components/DataSheetGrid";
import { Column, keyColumn, textColumn } from "react-datasheet-grid";
import { Avatar, Button, Group, Select, Overlay, Tooltip, CloseButton } from "@mantine/core";
import { IconRefresh, IconDeviceFloppy, IconUpload, IconCircleFilled } from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";
import LoadingSpinner from "../../../../components/loadingSkelaton/LoadingSpinner";
import NoDataForTargets from "../noData/NoDataForTargets";
import GenericModal from "../../../../containers/GenericModal";
import UpdateActivities from "../../../../containers/activityExcelImport/update/UpdateActivities";
import { commonFetch, fetchWBSActivities, unitActivityUpdate, upsertTargets } from "../../../../api/transactionServer";
import { CommonApiEntities } from "../../../../models/enums";
import { formatPercentage } from "../../../../utils/utils";
import moment from "moment";
import useStyles from './WeeklyTargetsStyles'; // reuse the same styles
import { Divider, Typography } from "@material-ui/core";
import { Instance } from "mobx-state-tree";
import { StoreModel } from "../../../../models/DataStore";

const transformWBSData = async ({ store, year }: { store: Instance<typeof StoreModel>; year: number; }) => {
    let data: any[] = [];
    try {
        const { data: unitActivityData } = await commonFetch({
            projectId: store.projectInfo.currentProject?.id || '',
            entity: CommonApiEntities.UNIT_ACTIVITIES,
            filters: { isDPR: true, planStart: `${year}-12-31`, planEnd: `${year}-01-01` }
        });

        const { data: wbsData } = await fetchWBSActivities({
            projectId: store.projectInfo.currentProject?.id || '',
            unitActivityIds: unitActivityData.map((ua) => ua.id),
            frequency: "WEEK",
            targetPeriod: `${year}`
        });

        const transformed = unitActivityData.map((unitAct) => {
            const unit = {
                unitTitle: unitAct.unitTitle,
                unitActivity: unitAct.id,
                activity: unitAct.title,
                plannedStart: moment(unitAct.planStart).format('DD-MM-YYYY'),
                plannedEnd: moment(unitAct.planEnd).format('DD-MM-YYYY'),
                plannedTotalQty: unitAct.plannedTotalQuantity,
                unitOfMeasure: unitAct.unitOfMeasure,
                actualStart: moment(unitAct.actualStart).format('DD-MM-YYYY'),
                blockName: unitAct.blockName,
                floorLabel: unitAct.floorLabel,
                unitTypeId: unitAct.unitTypeId,
                totalAchievedForYearSoFar: 0,
                changedBy: 'SYSTEM'
            };
            const weeksMap = {};
            for (let week = 1; week <= 53; week++) {
                const weekKey = `week${week}`;
                const targetWeek = `${year}-W${week.toString().padStart(2, '0')}`;
                const match = wbsData.find((w) => w.targetPeriod.startsWith(targetWeek) && w.unitActivityId === unitAct.id);
                weeksMap[weekKey] = match ? match.quantity : undefined;
                weeksMap['quantities'] = {
                    ...weeksMap['quantities'],
                    [`week${week}`]: {
                        'target': match ? match.quantity : null,
                        'achieved': match ? match.achievedQuantity : null
                    }
                }
            }

            return { ...unit, ...weeksMap };
        });

        const sortedData = Object.values(transformed).sort((a: any, b: any) => {
            // Sort by unitTitle
            // const unitTitleComparison = a.unitTitle.localeCompare(b.unitTitle);
            // if (unitTitleComparison !== 0) return unitTitleComparison;

            // Sort by activity
            // const activityComparison = a.activity.localeCompare(b.activity);
            // if (activityComparison !== 0) return activityComparison;

            // Sort by plannedStart (handle missing or invalid dates)
            const dateA = a.plannedStart ? moment(a.plannedStart, "DD-MM-YYYY").valueOf() : 0;
            const dateB = b.plannedStart ? moment(b.plannedStart, "DD-MM-YYYY").valueOf() : 0;
            return dateA - dateB;
        });

        data = sortedData
    } catch (error) {
        console.error("Error loading Weekly Target Data:", error);
    }
    return data
}

const WeeklyTargets = () => {
    const classes = useStyles();
    const store = useStore();
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [data, setData] = useState<any[]>([]);
    const [changes, setChanges] = useState({});
    const [loading, setLoading] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const currentYear = new Date().getFullYear();

    const baseColumns: Column<any>[] = [
        {
            ...keyColumn("plannedTotalQty", textColumn),
            title: "Total Qty",
            minWidth: 100,
            cellClassName: `${classes.cellChanged}`,
        },
        {
            ...keyColumn('plannedStart', {
                component: ({ rowData, rowIndex, setRowData, focus }) => {
                    return (
                        <DatePickerInput
                            value={rowData ? moment(rowData, "DD-MM-YYYY").toDate() : null}
                            onChange={(val) => {
                                const formattedDate = val ? moment(val).format("DD-MM-YYYY") : "";
                                setRowData(formattedDate);
                            }}
                            placeholder="Select date"
                            size="xs"
                            // clearable
                            dropdownType="modal" // Prevents UI glitches when inside a grid
                            onClick={(e) => e.stopPropagation()} // Prevents accidental cell deselection
                            autoFocus={focus} // Ensures smooth keyboard navigation
                            styles={{ input: { cursor: "pointer", textAlign: "center" } }}
                            inputMode='text'
                            disabled={year < currentYear || data[rowIndex]['actualStart']}
                            sx={{
                                ["& .mantine-DatePickerInput-label"]: { fontSize: '0.8525em', color: '#585858', fontWeight: 600 },
                                ["& .mantine-DatePickerInput-input"]: { marginTop: '0.5em', fontSize: '0.9em', marginBottom: '0.5em', borderRadius: 0, border: 'none', paddingRight: '1.8575rem', '&[data-disabled]': { backgroundColor: '#FAFAFA', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8575rem' } },
                                ["& .mantine-DatePickerInput-icon"]: { justifyContent: 'center' },
                            }}
                            valueFormat="DD/MMM/YYYY"
                        />
                    );
                },
                pasteValue: (value: any) => {
                    // Ensure `value` is an object
                    if (typeof value === "object" && value !== null) {

                        //  Extract the correct string date
                        let extractedValue = "";
                        if (typeof value.value === "string") {
                            extractedValue = value.value; // Direct string value
                        } else if (typeof value.value === "object" && value.value !== null) {
                            extractedValue = value.value?.rowData || ""; // Try nested value
                        } else {
                            extractedValue = value.rowData || "";
                        }

                        // Ensure it's a valid string
                        if (typeof extractedValue !== "string") {
                            return "";
                        }

                        // Trim and parse date
                        const cleanedValue = extractedValue.trim();

                        const parsedDate = moment(cleanedValue, ["DD-MM-YYYY", "YYYY-MM-DD"], true);
                        if (!parsedDate.isValid()) {
                            return "";
                        }

                        const formattedDate = parsedDate.format("DD-MM-YYYY");
                        return formattedDate;
                    }

                    // If it's already a string, process it directly
                    if (typeof value === "string") {
                        const parsedDate = moment(value.trim(), ["DD-MM-YYYY", "YYYY-MM-DD"], true);
                        return parsedDate.isValid() ? parsedDate.format("DD-MM-YYYY") : "";
                    }

                    return "";
                }
            }),
            title: 'Planned start',
            minWidth: 120,
            cellClassName: `${classes.cellChanged}`,
            disableKeys: false,
            deleteValue: () => "",
            copyValue: ({ rowData }) => rowData["plannedStart"],
            disabled: ({ rowData }) => year < currentYear || rowData["actualStart"]
        },
        {
            ...keyColumn('plannedEnd', {
                component: ({ rowData, setRowData, focus }) => {
                    return (
                        <DatePickerInput
                            value={rowData ? moment(rowData, "DD-MM-YYYY").toDate() : null}
                            onChange={(val) => {
                                const formattedDate = val ? moment(val).format("DD-MM-YYYY") : "";
                                setRowData(formattedDate);
                            }}
                            placeholder="Select date"
                            size="xs"
                            // clearable
                            dropdownType="modal" // Prevents UI glitches when inside a grid
                            onClick={(e) => e.stopPropagation()} // Prevents accidental cell deselection
                            autoFocus={focus} // Ensures smooth keyboard navigation
                            styles={{ input: { cursor: "pointer", textAlign: "center" } }}
                            inputMode='text'
                            disabled={year < currentYear}
                            sx={{
                                ["& .mantine-DatePickerInput-label"]: { fontSize: '0.8525em', color: '#585858', fontWeight: 600 },
                                ["& .mantine-DatePickerInput-input"]: { marginTop: '0.5em', fontSize: '0.9em', marginBottom: '0.5em', borderRadius: 0, border: 'none', paddingRight: '1.8575rem', '&[data-disabled]': { backgroundColor: '#FAFAFA', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8575rem' } },
                                ["& .mantine-DatePickerInput-icon"]: { justifyContent: 'center' },
                            }}
                            valueFormat="DD/MMM/YYYY"
                        />
                    );
                },
                pasteValue: (value: any) => {
                    // Ensure `value` is an object
                    if (typeof value === "object" && value !== null) {

                        //  Extract the correct string date
                        let extractedValue = "";
                        if (typeof value.value === "string") {
                            extractedValue = value.value; // Direct string value
                        } else if (typeof value.value === "object" && value.value !== null) {
                            extractedValue = value.value?.rowData || ""; // Try nested value
                        } else {
                            extractedValue = value.rowData || "";
                        }

                        // Ensure it's a valid string
                        if (typeof extractedValue !== "string") {
                            return "";
                        }

                        // Trim and parse date
                        const cleanedValue = extractedValue.trim();

                        const parsedDate = moment(cleanedValue, ["DD-MM-YYYY", "YYYY-MM-DD"], true);
                        if (!parsedDate.isValid()) {
                            return "";
                        }

                        const formattedDate = parsedDate.format("DD-MM-YYYY");
                        return formattedDate;
                    }

                    // If it's already a string, process it directly
                    if (typeof value === "string") {
                        const parsedDate = moment(value.trim(), ["DD-MM-YYYY", "YYYY-MM-DD"], true);
                        return parsedDate.isValid() ? parsedDate.format("DD-MM-YYYY") : "";
                    }

                    return "";
                }
            }),
            title: 'Planned end',
            minWidth: 120,
            cellClassName: `${classes.cellChanged}`,
            disableKeys: false,
            deleteValue: () => "",
            copyValue: ({ rowData }) => rowData["plannedEnd"],
            disabled: year < currentYear
        },
    ];

    // const columns = useMemo(() => {
    //     const totalWeeks = moment(`${year}-12-31`).isoWeeksInYear(); // 52 or 53 weeks
    //     const currentWeek = moment().week()
    //     const weekColumns: Column<any>[] = Array.from({ length: totalWeeks }, (_, index) => ({
    //         ...keyColumn(`week${index + 1}`, textColumn),
    //         title: `Week ${index + 1}`,
    //         minWidth: 80,
    //     }));
    //     return [...baseColumns, ...weekColumns];
    // }, [year]);

    const columns = useMemo(() => {
        const totalWeeks = moment(`${year}-12-31`).isoWeeksInYear(); // 52 or 53 weeks
        const currentWeek = moment().week(); // 🔵 Current ISO week number (1-53)
        const weekColumns: Column<any>[] = Array.from({ length: totalWeeks }, (_, index) => {
            const startOfWeek = moment().year(year).week(index + 1).clone().startOf('isoWeek');
            const endOfWeek = moment().year(year).week(index + 1).clone().endOf('isoWeek');
            return {
                ...keyColumn(`week${index + 1}`, ((index + 1) < currentWeek)
                    ? {
                        component: ({ rowData, setRowData, rowIndex, focus }) => {
                            const weekKey = `week${index + 1}`;
                            const targetValue = rowData >= 0 && data[rowIndex]['quantities']?.[weekKey]?.target;
                            const achievedValue = rowData >= 0 && data[rowIndex]['quantities']?.[weekKey]?.achieved;
                            const totalPercentAchieved = ((Number.parseFloat(achievedValue) / Number.parseFloat(targetValue)) * 100).toFixed(2);

                            return (
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '0em 0.5em', cursor: 'pointer' }}>
                                    {(rowData >= 0) && (
                                        <>
                                            <div style={{ marginTop: '-1.4em' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Avatar color="teal" size="xs" style={{ fontSize: '0.8em' }}>A</Avatar>
                                                    <span style={{ marginLeft: '0.25em', fontSize: '0.85em', opacity: 0.5 }}>{achievedValue}</span>
                                                </div>
                                                <Divider style={{ width: '20%' }} />
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Avatar color="red" size="xs" style={{ fontSize: '0.8em' }}>T</Avatar>
                                                    <span style={{ marginLeft: '0.25em', fontSize: '1.1em' }}>{targetValue}</span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {achievedValue > 0 && targetValue > 0 &&
                                        <Overlay opacity={0} zIndex={25}>
                                            <Tooltip
                                                label={<div style={{ display: 'flex', alignSelf: 'end' }}>{totalPercentAchieved}% achieved</div>}
                                                radius="xs"
                                                color={Number.parseFloat(totalPercentAchieved) >= 100 ? 'teal' : 'red'}
                                            >
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: '4px',
                                                    fontSize: '0.75em',
                                                    padding: '2px 5px',
                                                    borderRadius: '4px',
                                                    color: Number.parseFloat(totalPercentAchieved) >= 100 ? 'teal' : 'red',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <IconCircleFilled
                                                        color={Number.parseFloat(totalPercentAchieved) >= 100 ? 'teal' : 'red'}
                                                        style={{ marginRight: '0.5em' }}
                                                        width={'1em'}
                                                        height={'1em'} /> {formatPercentage(achievedValue, targetValue)}%
                                                </div>
                                            </Tooltip>
                                        </Overlay>}
                                </div>
                            );
                        },
                        deleteValue: () => "",
                        copyValue: ({ rowData }) => rowData || "",
                        disableKeys: false,
                        keepFocus: true,
                    }
                    : textColumn),
                title: (
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        <div >Week {index + 1}</div>
                        <div style={{ fontSize: '0.6em', color: '#005eff', opacity: .8 }}>
                            {startOfWeek.format('DD/MMM')} - {endOfWeek.format('DD/MMM')}
                        </div>
                    </div>
                ),
                cellClassName: ({ rowData, rowIndex }) => {
                    const plannedStart = moment(data[rowIndex]?.plannedStart, "DD-MM-YYYY");
                    const plannedEnd = moment(data[rowIndex]?.plannedEnd, "DD-MM-YYYY");
                    const weekMoment = moment().isoWeek(index + 1);
                    if (weekMoment.isBetween(plannedStart.clone().startOf('isoWeek'), plannedEnd.clone().endOf('isoWeek'), undefined, '[]') && (index + 1) >= currentWeek) {
                        return classes.plannedCell;
                    }
                    if (changes[rowIndex] && changes[rowIndex][`week${index + 1}`] && changes[rowIndex]["changedBy"] === 'USER' && (index + 1) >= currentWeek && data[rowIndex][`week${index + 1}`] !== changes[rowIndex][`week${index + 1}`]) {
                        return classes.updatedCell;
                    }
                    return classes.cellChanged;
                },
                disabled: year < currentYear || (index + 1) < currentWeek
            }
        });

        return [
            ...baseColumns,
            ...weekColumns
        ];
    }, [year, changes]);


    const loadData = async () => {
        setLoading(true);
        setIsSaveButtonDisabled(true);
        const transformedData = await transformWBSData({ store, year });
        setData(transformedData);
        setChanges(transformedData);
        setLoading(false);
    };

    useEffect(() => {
        loadData();
    }, [year]);

    const handleGridChange = (updatedData) => {
        setChanges((prevChanges) => {
            const newChanges = { ...prevChanges };
            updatedData.forEach((row, rowIndex) => {
                Object.keys(row).forEach((key) => {
                    if (row[key] !== prevChanges[rowIndex]?.[key]) {
                        const newValue = row[key];

                        if (!newChanges[rowIndex]) newChanges[rowIndex] = {};

                        if (key === "plannedEnd" || key === "plannedStart") {
                            const formattedDate = newValue ? moment(newValue, "DD-MM-YYYY").format("DD-MM-YYYY") : null;
                            if (formattedDate !== prevChanges[rowIndex]?.[key]) {
                                newChanges[rowIndex][key] = formattedDate;
                                newChanges[rowIndex]['changedBy'] = 'USER';
                            }
                        } else {
                            if (newValue !== prevChanges[rowIndex]?.[key]) {
                                newChanges[rowIndex][key] = (newValue >= 0 && !isNaN(newValue)) ? newValue : null;
                                newChanges[rowIndex]['changedBy'] = 'USER';
                            }
                        }

                    }
                });
            })
            setIsSaveButtonDisabled(false);
            return newChanges
        });
    };

    const handleSaveChanges = async () => {
        const scheduleList: any = [];
        const plannedUpdates: Record<string, any> = {}; //  Store plannedStart & plannedEnd in one object per unitActivityId

        Object.entries(changes).forEach(([rowIndex, rowData]) => {
            const unitActivityId = data[rowIndex]?.unitActivity;

            Object.entries(rowData as Record<string, any>).forEach(([key, value]) => {
                if (key.startsWith('week') && value !== null && value >= 0 && rowData && rowData['changedBy'] === 'USER') {
                    const weekNumber = key.replace('week', '').padStart(2, '0');
                    const targetPeriod = moment().year(year).week(Number(weekNumber)).startOf('isoWeek').format("YYYY-[W]WW");
                    scheduleList.push({
                        unitActivityId,
                        frequency: "WEEK",
                        targetPeriod,
                        quantity: Number(value).toFixed(2),
                    });
                } else if ((key === "plannedEnd" || key === "plannedStart") && value && rowData && rowData["changedBy"] === "USER") {
                    //  Parse date in "DD-MM-YYYY" format
                    const parsedDate = moment(value, "DD-MM-YYYY", true);
                    if (!parsedDate.isValid()) {
                        console.error(`Invalid date format: ${value} for ${key} (unitActivityId: ${unitActivityId})`);
                        return;
                    }

                    const formattedDate = parsedDate.utc().format("YYYY-MM-DD HH:mm:ss.SSS[Z]");

                    //  Check if an entry already exists for this unitActivityId
                    if (!plannedUpdates[unitActivityId]) {
                        plannedUpdates[unitActivityId] = {
                            projectId: store.projectInfo.currentProject?.id,
                            unitActivityId,
                        };
                    }

                    //  Assign the date to either planStart or planEnd
                    if (key === "plannedStart") {
                        plannedUpdates[unitActivityId].planStart = formattedDate;
                    } else if (key === "plannedEnd") {
                        plannedUpdates[unitActivityId].planEnd = formattedDate;
                    }
                } else if (key === "plannedTotalQty" && value && value >= 0 && rowData && rowData["changedBy"] === "USER") {
                    //  Check if an entry already exists for this unitActivityId
                    if (!plannedUpdates[unitActivityId]) {
                        plannedUpdates[unitActivityId] = {
                            projectId: store.projectInfo.currentProject?.id,
                            unitActivityId,
                        };
                    }
                    plannedUpdates[unitActivityId].plannedTotalQuantity = Number(Number.parseFloat(value).toFixed(2));
                }
            });
        });

        try {
            const plannedUpdatesArray = Object.values(plannedUpdates); // Convert object into array
            if (plannedUpdatesArray.length > 0) {
                await Promise.all(plannedUpdatesArray.map(update => unitActivityUpdate({ payload: update })));
            }

            if (scheduleList.length > 0) {
                await upsertTargets({ projectId: store.projectInfo.currentProject?.id || '', scheduleList });
            }
            setIsSaveButtonDisabled(true);
            await loadData();
        } catch (error) {
            console.error("Save failed:", error);
        }
    };

    const yearOptions = Array.from({ length: 3 }, (_, i) => ({
        value: `${currentYear - 1 + i}`,
        label: `${currentYear - 1 + i}`,
    }));


    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <h3 className={classes.title}>Targets by Week</h3>
                <div className={classes.title} style={{ minWidth: 190, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}><Avatar color="red" size={'2em'}>T</Avatar><span style={{ marginLeft: '0.2em' }}>Target</span></div>
                    <div style={{ display: 'flex', alignItems: 'center' }}><Avatar color="teal" size={'2em'}>A</Avatar><span style={{ marginLeft: '0.2em' }}>Achieved</span></div>
                </div>
            </div>
            <div className={classes.dropdownDiv}>
                <div className={classes.dropdownsContainer}>
                    <Select
                        label="Choose Year"
                        value={String(year)}
                        onChange={(value) => setYear(Number(value))}
                        data={yearOptions}
                        placeholder="Select year"
                        styles={{ dropdown: { maxWidth: 200 } }}
                        className={classes.dropdown}
                        sx={{
                            ["& .mantine-Select-input"]: { borderRadius: 0 }
                        }}
                    />
                </div>
                <div className={classes.buttonsContainer}>
                    <Button
                        onClick={handleSaveChanges}
                        className={classes.saveButton}
                        disabled={isSaveButtonDisabled}
                    >
                        Save <IconDeviceFloppy width={'1em'} height={'1em'} style={{ marginLeft: '0.25em' }} />
                    </Button>

                    <Button
                        onClick={loadData}
                        className={classes.saveButton}
                    >
                        Refresh <IconRefresh width={'1em'} height={'1em'} style={{ marginLeft: '0.25em' }} />
                    </Button>

                    {/* {store.features.accessConfig.dpr.update && <Button
                        onClick={() => setOpenModal(true)}
                        className={classes.saveButton}
                    >
                        Bulk Import <IconUpload width={'1em'} height={'1em'} style={{ marginLeft: '0.25em' }} />
                    </Button>} */}
                </div>
            </div>

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                    <LoadingSpinner />
                </div>
            ) : !data.length ? (
                <NoDataForTargets />
            ) : (
                <div style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                    <DataGrid key={year} data={data} columns={columns} onChange={handleGridChange} reportType={'monthly'} />
                </div>
            )}

            <GenericModal
                loading={false}
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
                actions={[]}
                fullWidth
                maxWidth={"md"}
                // bgColor={materialStatusColours[store.ticket.currentTicket?.statusCategory || "open"]?.bg}
                bgColor={"#999999"}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Group position='apart' w="100%" style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography title='BulkImport' variant='h5' style={{ padding: '0.5em', fontWeight: 'bold' }}>Bulk Import</Typography>
                        <CloseButton color='red' title='Close' onClick={() => setOpenModal(false)} style={{ marginRight: '0.5em' }} />
                    </Group>
                    <UpdateActivities importModel={store.planning.bulkImport} report={"targets"} />
                </div>
            </GenericModal>
        </div>
    );
};

export default observer(WeeklyTargets);
