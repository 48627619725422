import React, { useEffect } from 'react';
import { Tabs } from '@mantine/core';
import DailyTargets from './daily/DailyTargets';
import MonthlyTargets from './monthly/MonthlyTargets';
import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../models/ProvideModel';
import WeeklyTargets from './weekly/WeeklyTargets';

const DPRTargets = () => {
    const store = useStore();
    useEffect(() => { store.features.fetchAccessConfig(); }, [])
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
            {/* <Typography title='DPRTargets' variant='h5' style={{ padding: '0.5em', fontWeight: 'bold' }}>DPR Targets</Typography> */}
            <Tabs defaultValue="daily" style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                <Tabs.List>
                    <Tabs.Tab value="monthly" >Monthly</Tabs.Tab>
                    <Tabs.Tab value="weekly" >Weekly</Tabs.Tab>
                    <Tabs.Tab value="daily" >Daily</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="monthly" pt="xs" style={{ overflow: 'auto'}}>
                    <MonthlyTargets />
                </Tabs.Panel>

                <Tabs.Panel value="weekly" pt="xs" style={{ overflow: 'auto'}}>
                    <WeeklyTargets />
                </Tabs.Panel>

                <Tabs.Panel value="daily" pt="xs" style={{ overflow: 'auto'}}>
                    <DailyTargets/>
                </Tabs.Panel>

            </Tabs>
        </div>
    )
}

export default observer(DPRTargets);